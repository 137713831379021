import { observer } from 'mobx-react-lite';
import StopGoKeywordStore from './Keyword/Keyword.store';
import { useContext, useEffect, useState } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import CompanyAccountsFilter from '../../components/Filters/CompanyAccountsFilter/CompanyAccountsFilter';
import NavFilter from '../../components/Filters/NavFilter/NavFilter';
import { StoreContext } from '../../stores';
import Backend from '../../stores/newBackend';
import { useTranslation } from 'react-i18next';
import MagicTable from '../../components/MagicTable/MagicTable';
import { AddKeywordModal } from './Modals/AddKeywordModal';
import SwitcherCell from '../../components/MagicTable/SwitcherCell/SwitcherCell';
import DateCell from '../../components/MagicTable/DateCell/DateCell';
import StopGoKeywordsActionsCell from './Keyword/StopGoKeywordsActionsCell';
import FlagCell from '../../components/MagicTable/FlagCell/FlagCell';
import LinkCell from '../../components/MagicTable/LinkCell/LinkCell';
import { toast } from 'react-toastify';
import { IKeyword } from '../../swagger_client/src/model/Keyword';

import classes from './Keywords.module.css';

function StopGoKeywords() {
  const [addModalDisplayed, setAddModalDisplayed] = useState(false);
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [stopGoStore] = useState(
      () => new StopGoKeywordStore(currentUser?.id || '', authStore.token)
    ),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Stop an Go - keywords'));

    return () => {
      stopGoStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  const toggleEnable = async (item: IKeyword) => {
    const newState = !item.enable;
    const result = await Backend.updateStopGoKeywordEnable(authStore.token, item.ID, newState);
    if (result) {
      stopGoStore.handleFetch();
      toast(newState ? t('keyword enable') : t('keyword disable'), { type: 'success' });
    } else {
      toast(t('can\'t enable keyword'), { type: 'error' });
    }
  };

  const toggleExclude = async (item: IKeyword) => {
    const newState = !item.saveToGoogle;
    const result = await Backend.updateStopGoKeywordExclude(authStore.token, item.ID, newState);
    if (result) {
      stopGoStore.handleFetch();
      toast(newState ? t('keyword exclude') : t('keyword include'), { type: 'success' });
    } else {
      toast(t('can\'t exclude keyword'), { type: 'error' });
    }
  };

  return (
    <>
      <div className={classes.pageHeader}>
        <button
          className={classes.add}
          onClick={() => setAddModalDisplayed(true)}
        >{t('Add keyword')}</button>
      </div>
      <div className='tableContainer'>
        <div className='tableContainerFilter'>
          <NavFilter
            isLoading={stopGoStore.isLoadingCount}
            pageStore={stopGoStore}
            counters={stopGoStore.counters}
          >
            <CompanyAccountsFilter label={t('company account')} name='id' />
          </NavFilter>
        </div>
        <div className='tableBox'>
          <div className='table'>
            <MagicTable
              store={stopGoStore}
              showInputSearch={false}
              noFixedHeader
              simpleHeader
            >
              <LinkCell field="keyword.content" link="stop-go-keyword-management/<ID>" />
              <FlagCell field="keyword.localization.ISO2" />
              <DateCell field="createdDate" />
              <SwitcherCell
                field="saveToGoogle"
                onClick={toggleExclude}
              />
              <SwitcherCell
                field="enable"
                onClick={toggleEnable}
              />
              <StopGoKeywordsActionsCell field="actions" />
            </MagicTable>
          </div>
        </div>
        <Pagination
          total={stopGoStore.count}
          perPage={stopGoStore.perPage}
          page={stopGoStore.page}
          handleSetPage={(pageNumber) => stopGoStore.setPage(pageNumber)}
        />
      </div>
      {addModalDisplayed && (
        <AddKeywordModal
          onClose={(success: boolean) => {
            if (success) {
              stopGoStore.handleFetch();
            }

            setAddModalDisplayed(false);
          }}
        />
      )}
    </>
  );
}

export default observer(StopGoKeywords);
