import { makeObservable, observable } from 'mobx';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import _ from 'lodash';

import PageStore from '../../../stores/page.store';
import Backend from '../../../stores/newBackend';
import { IStopGoKeyword } from '../../../swagger_client/src/model/StopGoKeyword';
import { IKeyword } from '../../../swagger_client/src/model/Keyword';

export default class BrandKeywordsStore extends PageStore {
  endpoint = 'justforabstract';
  selectedItem = null;
  tableHeader = [
    {
      name: 'brand.label',
      title: 'Brand',
      sortable: true,
      center: true,
    },
    { name: 'keyword.content', title: 'mot clé', sortable: true, center: true },
    {
      name: 'keyword.localization.ISO2',
      title: 'Location',
      sortable: true,
      center: true,
    },
    {
      name: 'averageCTR',
      title: 'Average CTR',
      sortable: true,
      center: true
    },
    {
      name: 'averageCPC',
      title: 'Average CPC',
      sortable: true,
      center: true
    },
    {
      name: 'createdDate',
      title: 'Creation date',
      sortable: true,
      center: true,
    },
    /* {
      name: 'scenarios',
      title: 'Scenarios',
      sortable: true,
      center: true,
    }, */
    {
      name: 'saveToGoogle',
      title: 'Excluded',
      center: true,
    },
    {
      name: 'enable',
      title: 'Enabled',
      center: true,
    },
    { name: 'actions', title: '', center: true },
  ];
  direction = 'desc';
  order = 'brandLabel';
  token = '';
  brandID = '';

  constructor(userId: string, token: string, brandID: string) {
    super(userId);
    makeObservable(this, {
      selectedItem: observable,
    });

    this.token = token;
    this.brandID = brandID;
    this.defaultFilters = {};
    if (!this.filters) this.filters = { ...this.defaultFilters };
  }

  setSelectedItem(value: any) {
    this.selectedItem = value;
  }
  async handleCount() { }

  async handleFetch() {
    this.isLoading = true;
    this.isLoadingCount = true;
    try {
      let results = await Backend.loadStopGoKeyword(this.token, this.brandID);
      this.setItems(results.map(result => ({
        ...result,
        averageCPC: result.averageCPC.toString(),
        averageCTR: result.averageCTR.toString()
      })));
      this.setCount(results.length);
      // For testing purpose only
      /* if (results.length === 0) {
        this.setItems([
          {
            ID: 'id',
            brandLabel: 'brand',
            keywordContent: 'Keyword',
            keywordLocation: 'US',
            saveToGoogle: true,
            createdDate: '2025-03-29T11:00:00',
            enable: true,
            averageCPC: 10,
            averageCTR: 11          
          }
        ]);
        this.setCount(1);
      } */
    } catch (e: any) {
      toast.error(i18next.t(e.message.toString()) as string);
    }
    this.isLoading = false;
    this.isLoadingCount = false;
  }

  get counters() {
    return [{ txt: 'keywords', count: this.count }];
  }

  filterSearch(item: any) {
    const { brandLabel } = item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (brandLabel) {
      brandLabel.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    return count > 0 ? true : false;
  }
}
