import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import Pagination from '../../../components/Pagination/Pagination';
import { StoreContext } from '../../../stores';
import { useTranslation } from 'react-i18next';
import MagicTable from '../../../components/MagicTable/MagicTable';
import DateCell from '../../../components/MagicTable/DateCell/DateCell';
import ScopeCell from '../../../components/MagicTable/ScopeCell/ScopeCell';
import { StopGoGoogleConnectItemActionsCell } from './StopGoGoogleConnectItemActionsCell';
import classes from './GoogleConnectKeywords.module.css';
import GoogleConnectItemKeywordsStore from './GoogleConnectItemKeywords.store';
import { IStopGoGoogleConnect } from '../../../types/stopgogoogleconnect.interface';
import { MapDataModal } from '../Modals/MapDataModal';
import FlagCell from '../../../components/MagicTable/FlagCell/FlagCell';

interface GoogleConnectKeywordsProps {
  googleConnectItem: IStopGoGoogleConnect;
}

function GoogleConnectKeywords({
  googleConnectItem
}: GoogleConnectKeywordsProps) {
  const [mapModalDisplayed, setMapModalDisplayed] = useState(false);
  const { authStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [googleConnectStore] = useState(
      () => new GoogleConnectItemKeywordsStore(currentUser?.id || '', authStore.token, googleConnectItem.ID)
    ),
    { t } = useTranslation();

  useEffect(() => {
    return () => {
      googleConnectStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  const onCloseMapDataModal = () => {
    setMapModalDisplayed(false);
    googleConnectStore.handleFetch();
  };

  return (
    <div className={classes.container}>
      <div className={classes.pageHeader}>
        <button
          className={classes.map}
          onClick={() => setMapModalDisplayed(true)}
        >{t('Link keywords')}</button>
      </div>
      <div className='tableContainer'>
        <div className='tableContainerFilter'>
        </div>
        <div className='tableBox'>
          <div className='table'>
            <MagicTable
              store={googleConnectStore}
              showInputSearch={false}
              noFixedHeader
              simpleHeader
            >
              <DateCell field="createdDate" />
              <ScopeCell field="scopes" />
              <FlagCell field="localization.ISO2" />
              <StopGoGoogleConnectItemActionsCell
                googleConnectItem={googleConnectItem}
              />
            </MagicTable>
          </div>
        </div>
        <Pagination
          total={googleConnectStore.count}
          perPage={googleConnectStore.perPage}
          page={googleConnectStore.page}
          handleSetPage={(pageNumber) => googleConnectStore.setPage(pageNumber)}
        />
      </div>
      {mapModalDisplayed && (
        <MapDataModal
          googleConnectItem={googleConnectItem}
          onClose={onCloseMapDataModal}
        />
      )}
    </div>
  );
}

export default observer(GoogleConnectKeywords);
