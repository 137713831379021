import { action, makeObservable, observable } from 'mobx';
import PageStore from '../../../stores/page.store';
import Backend from '../../../stores/newBackend';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import _ from 'lodash';
import dayjs from 'dayjs';

export default class GoogleConnectItemDataStore extends PageStore {
  endpoint = 'justforabstract';
  selectedItems: any[] = [];
  selectedItem = null;
  tableHeader = [
    {
      name: 'selectAll',
      value: 0,
      handleClick: () => this.toggleSelectAll(),
      center: true,
    },
    {
      name: 'date',
      title: 'Date',
      sortable: true,
      center: true,
      customSort: (a: any, b: any) => {
        return a.date.localeCompare(b.date);
      }
    },
    {
      name: 'term',
      title: 'Keywords',
      sortable: true,
      center: true,
      customSort: (a: any, b: any) => {
        return a.term.localeCompare(b.term);
      }
    },
    {
      name: 'impressionSEO',
      title: 'Impression SEO',
      sortable: true,
      center: true,
      maxWidth: '600px',
      customSort: (a: any, b: any) => {
        return a.impressionSEO - b.impressionSEO;
      }
    },
    {
      name: 'impressionSEA',
      title: 'Impression SEA',
      sortable: true,
      center: true,
      maxWidth: '600px',
      customSort: (a: any, b: any) => {
        return a.impressionSEA - b.impressionSEA;
      }
    },
    {
      name: 'clickSEO',
      title: 'Click SEO',
      sortable: true,
      center: true,
      maxWidth: '600px',
      customSort: (a: any, b: any) => {
        return a.clickSEO - b.clickSEO;
      }
    },
    {
      name: 'clickSEA',
      title: 'Click SEA',
      sortable: true,
      center: true,
      maxWidth: '600px',
      customSort: (a: any, b: any) => {
        return a.clickSEA - b.clickSEA;
      }
    },
  ];
  direction = 'desc';
  order = 'Keywords';
  token = '';
  customerID = '';

  constructor(userId: string, token: string, customerID: string) {
    super(userId);
    makeObservable(this, {
      selectedItems: observable,
      selectedItem: observable,
      toggleSelectAll: action,
      toggleSelectOne: action,
      setSelectedItem: action,
    });

    this.token = token;
    this.customerID = customerID;
    this.defaultFilters = {};
    this.filters = { ...this.defaultFilters };
  }

  toggleSelectAll() {
    if (this.items.length === this.selectedItems.length) {
      this.selectedItems = [];
      this.tableHeader[0].value = 0;
      this.setSelectedItem(null);
    } else {
      this.selectedItems = this.items.map((item) => item.ID);
      this.setSelectedItem(this.items[0]);
      this.tableHeader[0].value = 1;
    }
  }

  toggleSelectOne(item: any) {
    this.selectedItems = _.xor([item.ID], this.selectedItems);
    this.setSelectedItem(this.selectedItems.length === 1 ? item : null);
  }

  setSelectedItem(value: any) {
    this.selectedItem = value;
  }
  async handleCount() { }

  async handleFetch() {
    this.selectedItems = [];
    this.setSelectedItem(null);
    this.setIsLoading(true);
    this.isLoadingCount = true;

    try {
      let result = await Backend.loadGoogleConnectItemStats(this.token, this.customerID);
      // For testing only
      /* result = [
        {
          ID: '1',
          "date": "2024-12-26T08:00:00.000Z",
          "term": "aa",
          "country": "FRA",
          "SEO": {
              "clicks": 1,
              "impressions": 8
          },
          "SEA": {
              "clicks": 4,
              "impressions": 0
          }
        },
        {
          ID: '2',
          "date": "2024-12-28T08:00:00.000Z",
          "term": "01 test seb",
          "country": "FRA",
          "SEO": {
              "clicks": 3,
              "impressions": 8
          },
          "SEA": {
              "clicks": 3,
              "impressions": 8
          }
        },
        {
          ID: '3',
          "date": "2025-04-07T08:00:00.000Z",
          "term": "camif armoires2",
          "country": "FRA",
          "SEO": {
              "clicks": 2,
              "impressions": 8
          },
          "SEA": {
              "clicks": 4,
              "impressions": 10
          }
        }
      ]; */
      let formattedResult = result.map((r) => ({
        ...r,
        keywords: r.term,
        impressionSEO: r.SEO.impressions.toString(),
        impressionSEA: r.SEA.impressions.toString(),
        clickSEO: r.SEO.clicks.toString(),
        clickSEA: r.SEA.clicks.toString()
      }));
      // Local filtering for the moment
      if (Object.keys(this.filters).length > 0) {
        if (this.filters['keywords']) {
          const keywordsToUse = this.filters['keywords'].value
            .map((v: { value: string; label: string }) => v.label);
          formattedResult = formattedResult.filter(r => keywordsToUse.includes(r.keywords));
        }
        if (this.filters['date']) {
          const startDate = dayjs(this.filters['date'].valueNew.start).toDate();
          const endDate = dayjs(this.filters['date'].valueNew.end).toDate();
          formattedResult = formattedResult.filter(r => {
            const date = dayjs(r.date).toDate();
            return (
              date.getTime() >= startDate.getTime() &&
              date.getTime() <= endDate.getTime()
            );
          });
        }
      }
      this.setItems(formattedResult.sort((s1, s2) => {
        return s1.date.localeCompare(s2.date);
      }));
      this.setCount(result.length);
    } catch (e: any) {
      toast.error(i18next.t(e.message.toString()) as string);
    }

    this.setIsLoading(false);
    this.isLoadingCount = false;
  }

  get counters() {
    return [{ txt: 'item', count: this.count }];
  }

  filterSearch(item: any) {
    const { name } = item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (name) {
      name.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    return count > 0 ? true : false;
  }

}
