import _ from 'lodash';
import { TermApi } from '../../../swagger_client/src';
import { useTranslation } from 'react-i18next';
import useGet from '../../Hooks/useGet';
import containsValueMapper from '../../../Utils/filter/containsValueMapper';
import { createInputValueRegexContent } from '../../../Utils/filter/createFilterInputValue';
import Backend from '../../../stores/newBackend';


const getDefaultWhere = (brandID: string[] | undefined) => {
  const defaultWhere = {} as {
    brandId: { inq: string[] } | undefined;
  };
  if (brandID) {
    defaultWhere.brandId = { 'inq': brandID };
  }

  return defaultWhere;
};


export default function useKeywordsFilter(filterKey: string = 'keyword', customerID: string, token: string, options: any[]) {
  const { t } = useTranslation();

  const { data } = useGet<any[] | undefined>((f: Object) => 
    Backend.loadGoogleConnectItemKeywords(token, customerID)
  );

  async function promiseOptions(inputValue?: string) {
    const items = await Backend.loadGoogleConnectItemKeywords(token, customerID);

    const options = items
      .filter(item => {
        if (!inputValue) return true;
        return item.details.content.includes(inputValue);
      })
      .map((o) => {
        return {
          label: o.details.content,
          value: o.details.ID,
        };
      });
    return options
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  return {
    fetcher: promiseOptions,
    options: {
      placeholder: t('Select keyword'),
      isMulti: true,
      creatable: true,
      ...options
    },
    count: data?.length,
    valueMapper: containsValueMapper
  };
}
