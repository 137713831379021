import { observer } from 'mobx-react';
import useKeywordsFilter from './useKeywordsFilter';
import { FilterChild } from '../../../types/filter.interface';
import AsyncCreateFilter from '../AsyncCreateFilter/AsyncCreateFilter';
import { useContext } from 'react';
import { StoreContext } from '../../../stores';

interface Props extends FilterChild {
  readonly filterKey: string
  readonly customerID: string;
}

function KeywordsFilter(props: Props) {
  const { authStore } = useContext(StoreContext);
  const useProps = useKeywordsFilter(props.filterKey, props.customerID, authStore.token, props?.options, ),
    passProps = { ...props, ...useProps };

  return <AsyncCreateFilter {...passProps} />;
}

export default observer(KeywordsFilter);
