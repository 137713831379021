import { Link } from 'react-router-dom';
import { FilePenLine } from 'lucide-react';
import classes from './StopGoBrandsActionsCell.module.css';

interface StopGoBrandsActionsCellProps {
  field?: string;
  item?: any;
}

export default function StopGoBrandsActionsCell({
  item
}: StopGoBrandsActionsCellProps) {
  return (
    <div className={classes.container}>
      <Link to={`stop-go-management/${item.ID}`}><FilePenLine className={classes.actionIcon} /></Link>
    </div>
  );
}
