import { useState } from 'react';
import { Trash2 } from 'lucide-react';
import StopGoStore from '../StopGo.store';
import classes from './StopGoGoogleConnectItemActionsCell.module.css';
import { DeleteRowModal } from '../Modals/DeleteRowModal';
import { IStopGoGoogleConnect } from '../../../types/stopgogoogleconnect.interface';

interface Props {
  readonly store?: StopGoStore
  readonly item?: any
  googleConnectItem?: IStopGoGoogleConnect;
}

export function StopGoGoogleConnectItemActionsCell({ item, googleConnectItem, store }: Props) {
  const [deleteOpened, setDeleteOpened] = useState(false);

  const onCloseDeleteRowModal = () => {
    setDeleteOpened(false);
    if (store) {
      store.handleFetch();
    }
  };

  return (
    <>
      <button
        className="minimal"
        onClick={() => setDeleteOpened(true)}
      >
        <Trash2 className={classes.actionIcon} />
      </button>
      {deleteOpened && (
        <DeleteRowModal
          id={item.id}
          googleConnectId={googleConnectItem?.ID}
          type="keyword"
          onClose={onCloseDeleteRowModal}
        />
      )}
    </>
  );
}
