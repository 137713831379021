import { observer } from 'mobx-react-lite';
import GoogleConnectStore from './GoogleConnect/GoogleConnect.store';
import { useContext, useEffect, useState } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import CompanyAccountsFilter from '../../components/Filters/CompanyAccountsFilter/CompanyAccountsFilter';
import NavFilter from '../../components/Filters/NavFilter/NavFilter';
import { StoreContext } from '../../stores';
import { useTranslation } from 'react-i18next';
import MagicTable from '../../components/MagicTable/MagicTable';
import DateCell from '../../components/MagicTable/DateCell/DateCell';
import StopGoGoogleConnectActionsCell from './GoogleConnect/StopGoGoogleConnectActionsCell';
import ScopeCell from '../../components/MagicTable/ScopeCell/ScopeCell';
import classes from './GoogleConnect.module.css';
import { AddGoogleConnectModal } from './Modals/AddGoogleConnectModal';
import KeywordsCell from '../../components/MagicTable/KeywordsCell/KeywordsCell';
import StateCell from '../../components/MagicTable/StateCell/StateCell';

function GoogleConnect() {
  const [addModalDisplayed, setAddModalDisplayed] = useState(false);
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [googleConnectStore] = useState(
      () => new GoogleConnectStore(currentUser?.id || '', authStore.token)
    ),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Google connect'));

    return () => {
      googleConnectStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={classes.pageHeader}>
        <button
          className={classes.add}
          onClick={() => setAddModalDisplayed(true)}
        >{t('Link account')}</button>
      </div>
      <div className='tableContainer'>
        <div className='tableContainerFilter'>
          <NavFilter
            isLoading={googleConnectStore.isLoadingCount}
            pageStore={googleConnectStore}
            counters={googleConnectStore.counters}
          >
            <CompanyAccountsFilter label={t('company account')} name='id' />
          </NavFilter>
        </div>
        <div className='tableBox'>
          <div className='table'>
            <MagicTable
              store={googleConnectStore}
              showInputSearch={false}
              noFixedHeader
              simpleHeader
            >
              <StateCell field="modifier" />
              <DateCell field="createdDate" />
              <ScopeCell field="scope" />
              <KeywordsCell field="keywords" />
              <StopGoGoogleConnectActionsCell field="actions" />
            </MagicTable>
          </div>
        </div>
        <Pagination
          total={googleConnectStore.count}
          perPage={googleConnectStore.perPage}
          page={googleConnectStore.page}
          handleSetPage={(pageNumber) => googleConnectStore.setPage(pageNumber)}
        />
      </div>
      {addModalDisplayed && (
        <AddGoogleConnectModal
          onClose={(success) => {
            setAddModalDisplayed(false);
            if (success) {
              googleConnectStore.handleFetch();
            }
          }}
        />
      )}
    </>
  );
}

export default observer(GoogleConnect);
