import { makeObservable, observable } from 'mobx';
import PageStore from '../../../stores/page.store';
import Backend from '../../../stores/newBackend';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import _ from 'lodash';

export default class StopGoStore extends PageStore {
  endpoint = 'justforabstract';
  selectedItem = null;
  tableHeader = [
    {
      name: 'keywords',
      title: 'Keywords',
      sortable: true,
      center: true,
    },

    {
      name: 'country',
      title: 'Country',
      sortable: true,
      center: true,
      maxWidth: '600px'
    },
    {
      name: 'savedToGoogle',
      title: 'Saved to Google',
      sortable: true,
      center: true,
      maxWidth: '600px'
    },
    { name: 'actions', title: '', align: 'right' },
  ];
  direction = 'desc';
  order = 'Keywords';
  token = '';
  brandId = '';

  constructor(userId: string, token: string, brandId: string) {
    super(userId);
    makeObservable(this, {
      selectedItem: observable,
    });

    this.token = token;
    this.brandId = brandId;
    this.defaultFilters = {};
    this.filters = { ...this.defaultFilters };
  }

  setSelectedItem(value: any) {
    this.selectedItem = value;
  }
  async handleCount() { }

  async handleFetch() {
    this.isLoading = true;
    this.isLoadingCount = true;

    try {
      // const result = await Backend.loadGoogleConnect(this.token);
      const result = await Backend.loadStopGoKeyword(this.token, this.brandId);
      this.count = result.length;
      this.setItems(result/* .map(r => ({
        ...r,
        id: '1',
        keywords: 'test',
        country: 'France',
        savedToGoogle: true
      }))*/);
    } catch (e: any) {
      toast.error(i18next.t(e.message.toString()) as string);
    }

    this.isLoading = false;
    this.isLoadingCount = false;
  }

  get counters() {
    return [{ txt: 'item', count: this.count }];
  }

  filterSearch(item: any) {
    const { name } = item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (name) {
      name.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    return count > 0 ? true : false;
  }

  async toggleField(item: any, field: string) {
    this.items = this.items.map(it => item.id === it.id ? ({ ...it, [field]: !item[field] }) : it);
    try {
      await Backend.updateStopGoKeyword(item.id, { [field]: !item[field] });
      return {
        ...item,
        [field]: !item[field]
      };
    } catch(err) {
      // Revert
      this.items = this.items.map(it => item.id === it.id ? ({ ...it, [field]: !item[field] }) : it);
      return null;
    }
  }
}
