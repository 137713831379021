import GoogleConnectPanel from '../Utils/GoogleConnectPanel';
import classes from './KeywordItemContent.module.css';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../stores';
import { useContext, useEffect, useState } from 'react';
import KeywordItemStore from './KeywordItem.store';
import { KeywordDetails } from './KeywordDetails';
import Backend from '../../../stores/newBackend';
import type { IStopGoKeyword } from '../../../types/stopgokeyword.interface';

interface KeywordItemContentProps {
  keywordId: string;
}

export const KeywordItemContent = ({
  keywordId
}: KeywordItemContentProps) => {
  const { authStore, uiStore } = useContext(StoreContext);
  const { currentUser } = authStore;
  const [keywordItemStore] = useState(
    () => new KeywordItemStore(currentUser?.id || '', authStore.token, keywordId)
  );
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState<IStopGoKeyword | null>(null);

  useEffect(() => {
    uiStore.setTitle(t('Keyword'));
    Backend.loadStopGoKeywordItem(authStore.token, keywordId)
      .then(keyword => {
        setKeyword(keyword);
        if (keyword) {
          uiStore.addBreadcrumbData('stop-go-keyword-management', keyword.ID, keyword?.keyword?.content);
        }
      });

    return () => {
      keywordItemStore.stopStore();
      uiStore.clearBreadcrumbData();

    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <GoogleConnectPanel>
          <KeywordDetails
            keyword={keyword}
          />
        </GoogleConnectPanel>
        <GoogleConnectPanel managedScroll={false}>
          { /* <KeywordKeywords keywordItemStore={keywordItemStore} /> */ }
        </GoogleConnectPanel>
      </div>
    </div>
  );
};
