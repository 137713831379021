import GoogleConnectPanel from '../Utils/GoogleConnectPanel';
import classes from './GoogleConnectItemContent.module.css';
import GoogleConnectKeywords from './GoogleConnectKeywords';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../stores';
import { useContext, useEffect } from 'react';
import { GoogleConnectItemDetails } from './GoogleConnectDetails';
import GoogleConnectData from './GoogleConnectData';
import { IStopGoGoogleConnect } from '../../../types/stopgogoogleconnect.interface';

interface GoogleConnectItemContentProps {
  googleConnectItem: IStopGoGoogleConnect;
}

export const GoogleConnectItemContent = ({
  googleConnectItem
}: GoogleConnectItemContentProps) => {
  const { uiStore } = useContext(StoreContext),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Google connect item'));
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <GoogleConnectPanel>
          <GoogleConnectItemDetails googleConnectItem={googleConnectItem} />
        </GoogleConnectPanel>
        <GoogleConnectPanel managedScroll={false}>
          <GoogleConnectKeywords googleConnectItem={googleConnectItem} />
        </GoogleConnectPanel>
      </div>
      <div className={classes.mainContainer}>
        <GoogleConnectPanel managedScroll={false} className={classes.mainPanel}>
          <GoogleConnectData googleConnectItem={googleConnectItem} />
        </GoogleConnectPanel>
      </div>
    </div>
  );
};
