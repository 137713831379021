import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import FullscreenModal from '../../../components/FullscreenModal/FullscreenModal';
import CancelButton from '../../../components/CancelButton/CancelButton';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import { SelectInput } from '../../../components/form/FormSelect';
import Backend from '../../../stores/newBackend';
import { IStopGoGoogleConnect } from '../../../types/stopgogoogleconnect.interface';

import classes from './MapDataModal.module.css';
import { StoreContext } from '../../../stores';
import { FormCheckbox } from '../../../components/form/FormCheckbox';
import LoaderSmall from '../../../components/_ui/LoaderSmall/LoaderSmall';
import ReactCountryFlag from 'react-country-flag';
import { extractCodesFromLocale } from '../../../Utils/i18n/locale';

type Inputs = {
  startDate: string;
  endDate: string;
}

interface MapDataModalPros {
  googleConnectItem: IStopGoGoogleConnect;
  onClose: () => void;
}

export function MapDataModal({
  googleConnectItem, onClose
}: MapDataModalPros) {
  const { t, i18n } = useTranslation();
  const { authStore } = useContext(StoreContext);
  const {
    control,
    handleSubmit,
  } = useForm<Inputs>();
  const [submitting, setSubmitting] = useState(false);
  const [keywords, setKeywords] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      Backend.loadBrandKeywords(authStore.token, googleConnectItem.ID),
      Backend.loadGoogleConnectItemKeywords(authStore.token, googleConnectItem.ID)
    ]).then(([brandKeywords, linkedKeywords]) => {
      setKeywords(
        brandKeywords
          .filter(brandKeyword => !linkedKeywords.find(linkedKeyword => brandKeyword.ID === linkedKeyword.details.ID))
      );
      setIsLoading(false);
    });
  }, []);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setSubmitting(true);
    const keywordsIds = [];
    for (const keywordItem of Object.keys(data)) {
      if ((data as any)[keywordItem]) {
        keywordsIds.push(keywordItem.replace('keyword-', ''));
      }
    }
    try {
      await Backend.mapStopGoKeywords(authStore.token, googleConnectItem.ID, keywordsIds);
      onClose();
      toast(t('Mapped keywords.'), { type: 'success' });
    } catch(err) {
      toast(t('Can\'t map keywords.'), { type: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FullscreenModal
      title={t('Map keywords')}
      onClose={onClose}
    >
      <div className={classes.modale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.content}>
            {isLoading ? (
              <LoaderSmall />
            ) : keywords.map(keyword => (
              <FormCheckbox
                key={keyword.id}
                name={`keyword-${keyword.id}`}
                label={
                  <>
                    {keyword.content} <ReactCountryFlag
                      className="emojiFlag"
                      countryCode={keyword.localization.ISO2 as string}
                    />
                  </>
                }
                control={control}
                disabled={submitting}
              />
            ))}
          </div>
          <div className={classes.containerButtons}>
            <CancelButton onClose={onClose} txt="Cancel" />
            <SubmitButton
              isLoading={submitting}
              txt={t('Map')}
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    </FullscreenModal>
  );
}
