import { observer } from 'mobx-react-lite';
import GoogleConnectItemStore from './BrandItem.store';
import { useContext, useEffect, useState } from 'react';
import Pagination from '../../../components/Pagination/Pagination';
import { StoreContext } from '../../../stores';
import { useTranslation } from 'react-i18next';
import MagicTable from '../../../components/MagicTable/MagicTable';
import DateCell from '../../../components/MagicTable/DateCell/DateCell';
import ScopeCell from '../../../components/MagicTable/ScopeCell/ScopeCell';
import { StopGoGoogleConnectItemActionsCell } from './StopGoGoogleConnectItemActionsCell';
import classes from './BrandKeywords.module.css';
import { AddSimpleKeywordModal } from '../Modals/AddSimpleKeywordModal';
import SwitcherCell from '../../../components/MagicTable/SwitcherCell/SwitcherCell';
import { toast } from 'react-toastify';
import BrandKeywordsStore from './BrandKeywords.store';
import Backend from '../../../stores/newBackend';
import { IKeyword } from '../../../swagger_client/src/model/Keyword';
import LinkCell from '../../../components/MagicTable/LinkCell/LinkCell';
import FlagCell from '../../../components/MagicTable/FlagCell/FlagCell';

interface BrandKeywordsProps {
  brandItemStore: GoogleConnectItemStore;
}

function BrandKeywords({
  brandItemStore
}: BrandKeywordsProps) {
  const [addModalDisplayed, setAddModalDisplayed] = useState(false);
  const { uiStore, authStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [brandKeywordsStore] = useState(
      () => new BrandKeywordsStore(currentUser?.id || '', authStore.token, brandItemStore.brandId)
    ),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Brand'));

    return () => {
      brandItemStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  const toggleEnable = async (item: IKeyword) => {
    const newState = !item.enable;
    const result = await Backend.updateStopGoKeywordEnable(authStore.token, item.ID, newState);
    if (result) {
      brandKeywordsStore.handleFetch();
      toast(newState ? t('keyword enable') : t('keyword disable'), { type: 'success' });
    } else {
      toast(t('can\'t enable keyword'), { type: 'error' });
    }
  };

  const toggleExclude = async (item: IKeyword) => {
    const newState = !item.saveToGoogle;
    const result = await Backend.updateStopGoKeywordExclude(authStore.token, item.ID, newState);
    if (result) {
      brandKeywordsStore.handleFetch();
      toast(newState ? t('keyword exclude') : t('keyword include'), { type: 'success' });
    } else {
      toast(t('can\'t exclude keyword'), { type: 'error' });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.pageHeader}>
        <button
          className={classes.add}
          onClick={() => setAddModalDisplayed(true)}
        >{t('Add keyword')}</button>
      </div>
      <div className='tableContainer'>
        <div className='tableContainerFilter'>
        </div>
        <div className='tableBox'>
          <div className='table'>
            <MagicTable
              store={brandKeywordsStore}
              showInputSearch={false}
              noFixedHeader
              simpleHeader
            >
              <LinkCell field="keyword.content" link="stop-go-keyword-management/<id>" />
              <FlagCell field="keyword.localization.ISO2" />
              <DateCell field="createdDate" />
              <SwitcherCell
                field="saveToGoogle"
                onClick={toggleExclude}
              />
              <SwitcherCell
                field="enable"
                onClick={toggleEnable}
              />
              <StopGoGoogleConnectItemActionsCell field="actions" />
            </MagicTable>
          </div>
        </div>
        <Pagination
          total={brandItemStore.count}
          perPage={brandItemStore.perPage}
          page={brandItemStore.page}
          handleSetPage={(pageNumber) => brandItemStore.setPage(pageNumber)}
        />
      </div>
      {addModalDisplayed && (
        <AddSimpleKeywordModal
          brandId={brandItemStore.brandId}
          onKeywordAdded={() => {
            brandKeywordsStore.handleFetch();
          }}
          onClose={() => setAddModalDisplayed(false)}
        />
      )}
    </div>
  );
}

export default observer(BrandKeywords);
