import { useTranslation } from 'react-i18next';
import { DetailsItem } from '../Utils/DetailsItem';
import type { IStopGoBrand } from '../../../types/stopgobrand.interface';
import { formatDate } from '../Utils/date';

interface BrandDetailsProps {
  brand: IStopGoBrand | null;
}

export const BrandDetails = ({
  brand
}: BrandDetailsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailsItem
        label={t('Brand')}
        value={brand?.brandLabel}
      />
      <DetailsItem
        label={t('Official domains')}
        value={brand?.domains?.join(', ')}
      />
      <DetailsItem
        label={t('Creation date')}
        value={brand?.createdDate ? formatDate(brand?.createdDate) : ''}
      />
      <DetailsItem
        label={t('Enabled')}
        value={brand?.state === 'enable' ? t('yes') : t('no') }
      />
    </>
  );
};
