import { useEffect, useRef } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { TagsInput } from '../../../components/form/FormTags';
import { useTranslation } from 'react-i18next';

type Inputs = {
  domains: any[];
}

interface OfficialDomainsCellProps {
  item?: any;
  field: string;
  onChange?: (item: any, domains: string[]) => void;
}

export default function OfficialDomainsCell({ item, onChange }: OfficialDomainsCellProps) {
  const { t } = useTranslation();
  const { control } = useForm<Inputs>();
  const values = (item.domains || []).map((domain: string) => ({
    value: domain,
    label: domain
  }));
  const currentDomains: any = useWatch({
    control,
    name: 'domains',
    defaultValue: values
  });
  const initializeRef = useRef(false);

  useEffect(() => {
    if (initializeRef.current && currentDomains) {
      onChange && onChange(item, currentDomains);
    }
    initializeRef.current = true;
  }, [currentDomains]);

  return (
    <TagsInput
      name="domains"
      defaultValue={values}
      placeholder={t('Define domain and press ENTER')}
      inline
      control={control}
    />
  );
}
