import { useEffect, useState, Fragment, useContext } from 'react';
import _ from 'lodash';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from '../../Router/routes';
import classes from './Breadcrumb.module.css';
import PropTypes from 'prop-types';
import { StoreContext } from '../../stores';
import { observer } from 'mobx-react-lite';

Breadcrumb.propTypes = {
  excludeHomeSection: PropTypes.bool
};

function Breadcrumb({ excludeHomeSection }) {
  const location = useLocation(),
    { uiStore } = useContext(StoreContext),
    { t } = useTranslation(),
    [links, setLinks] = useState([]);

  useEffect(() => {
    const locationLinks = _.drop(location.pathname.split('/'), 1),
      newLinks = [];
    locationLinks.forEach((link, index) => {
      //search in router route title and section
      const search = routes.find((route) => route.path.substring(1) === link);
      //if dynamic route (:key), add title without link (current page)
      if (index > 1 && locationLinks[index - 1] === 'capture') {
        if (locationLinks[index - 1] === 'capture') {
          newLinks.push(search ? search : { title: t('search engine capture') });
        }
      } else if (index > 0) {
        const title = uiStore.getBreadcrumbTitle(locationLinks[index - 1], link) || link;
        newLinks.push(search ? search : { title });
      } else {
        newLinks.push(search ? search : { title: link });
      }
    });
    setLinks(newLinks);
    // eslint-disable-next-line
  }, [location.pathname, uiStore.breadcrumbData]);

  return (
    <div className={classes.container}>
      <div className={classes.box}>
        <div className={classes.box}>
          {links.length > 0 && links[0].section && (links[0].section !== 'home' || (links[0].section === 'home' && !excludeHomeSection)) && (
            <>
              <div className={`${classes.navlink} ${classes.section}`}>
                <span className={classes.link}>{t(links[0].section)}</span>
              </div>
              <div className={classes.linkSeparator}>/</div>
            </>
          )}
          {links.length > 0 &&
            links.map((route, index) =>
              route.path ? (
                <Fragment key={route.path}>
                  <NavLink
                    className={classes.navlink}
                    to={route.path}
                  >
                    <div className={`${classes.link} ${index === links.length - 1 ? classes.last : ''}`}>
                      {t(route.title)}
                    </div>
                  </NavLink>
                  {index !== links.length - 1 && (
                    <div className={classes.linkSeparator}>/</div>
                  )}
                </Fragment>
              ) : (
                <Fragment key={route.title} >
                  <div
                    className={classes.navlink}
                  >
                    <div className={`${classes.link} ${index === links.length - 1 ? classes.last : ''}`}>
                      {t(route.title)}
                    </div>
                  </div>
                  {index !== links.length - 1 && (
                    <div className={classes.linkSeparator}>/</div>
                  )}
                </Fragment>
              )
            )}
        </div>
      </div>
    </div>
  );
}

export default observer(Breadcrumb);
