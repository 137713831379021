import GoogleConnectPanel from '../Utils/GoogleConnectPanel';
import classes from './BrandItemContent.module.css';
import BrandKeywords from './BrandKeywords';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../stores';
import { useContext, useEffect, useState } from 'react';
import BrandItemStore from './BrandItem.store';
import { BrandDetails } from './BrandDetails';
import Backend from '../../../stores/newBackend';
import type { IStopGoBrand } from '../../../types/stopgobrand.interface';

interface BrandItemContentProps {
  brandId: string;
}

export const BrandItemContent = ({
  brandId
}: BrandItemContentProps) => {
  const { authStore, uiStore } = useContext(StoreContext);
  const { currentUser } = authStore;
  const [brandItemStore] = useState(
    () => new BrandItemStore(currentUser?.id || '', authStore.token, brandId)
  );
  const { t } = useTranslation();
  const [brand, setBrand] = useState<IStopGoBrand | null>(null);

  useEffect(() => {
    uiStore.setTitle(t('Google connect item'));
    Backend.loadStopGoBrandItem(authStore.token, brandId)
      .then(brand => {
        setBrand(brand);
        if (brand) {
          uiStore.addBreadcrumbData('stop-go-management', brand.ID, brand?.brandLabel);
        }
      });

    return () => {
      brandItemStore.stopStore();
      uiStore.clearBreadcrumbData();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <GoogleConnectPanel>
          <BrandDetails
            brand={brand}
          />
        </GoogleConnectPanel>
        <GoogleConnectPanel managedScroll={false}>
          <BrandKeywords brandItemStore={brandItemStore} />
        </GoogleConnectPanel>
      </div>
    </div>
  );
};
