import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../../stores';
import { useTranslation } from 'react-i18next';
import MagicTable from '../../../components/MagicTable/MagicTable';
import DateCell from '../../../components/MagicTable/DateCell/DateCell';
import ScopeCell from '../../../components/MagicTable/ScopeCell/ScopeCell';
import { StopGoGoogleConnectItemActionsCell } from './StopGoGoogleConnectItemActionsCell';
import classes from './GoogleConnectData.module.css';
import GoogleConnectItemDataStore from './GoogleConnectItemData.store';
import { RetrieveDataModal } from '../Modals/RetrieveDataModal';
import { PurgeDataModal } from '../Modals/PurgeDataModal';
import CheckboxCell from '../../../components/MagicTable/CheckboxCell/CheckboxCell';
import { IStopGoGoogleConnect } from '../../../types/stopgogoogleconnect.interface';
import GroupActions from '../../../components/GroupActions/GroupActions';
import NavFilter from '../../../components/Filters/NavFilter/NavFilter';
import DateFilter from '../../../components/Filters/DateFilterNew/DateFilter';
import TagsFilter from '../../../components/Filters/TagsFilter/TagsFilter';
import { Filter } from '../../../types/filter.interface';
import TermsFilter from '../../../components/Filters/TermsFilter/TermsFilter';
import KeywordsFilter from '../../../components/Filters/Keyword/KeywordsFilter';

interface GoogleConnectDataProps {
  googleConnectItem: IStopGoGoogleConnect;
}

function GoogleConnectData({
  googleConnectItem
}: GoogleConnectDataProps) {
  const [retrieveModalDisplayed, setRetrieveModalDisplayed] = useState(false);
  const [purgeModalDisplayed, setPurgeModalDisplayed] = useState(false);
  const { authStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [googleConnectStore] = useState(
      () => new GoogleConnectItemDataStore(currentUser?.id || '', authStore.token, googleConnectItem.ID)
    ),
    { t } = useTranslation();

  useEffect(() => {
    return () => {
      googleConnectStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.pageHeader}>
        <button
          className={classes.purge}
          disabled={(googleConnectStore.selectedItems || []).length === 0}
          onClick={() => setPurgeModalDisplayed(true)}
        >{t('Purge datas')}</button>
        <button
          className={classes.retrieve}
          onClick={() => setRetrieveModalDisplayed(true)}
        >{t('Retrieve datas')}</button>
      </div>
      <div className='tableContainer'>
        <div className='tableContainerFilter'>
          <NavFilter
            isLoading={googleConnectStore.isLoadingCount}
            pageStore={googleConnectStore}
            counters={googleConnectStore.counters}
          >
            <DateFilter
              name="date"
              label="Date"
              filters={{} as Filter}
              setFilter={() => {}}
              dateMin={new Date()}
            />
            <KeywordsFilter
              label='keywords'
              name='keywords'
              filterKey=''
              customerID={googleConnectItem.ID}
            />
          </NavFilter>

          <GroupActions
            selected={googleConnectStore.selectedItems}
            actions={[]/*googleConnectStore.actions*/}
            isLoading={false/*googleConnectStore.isLoadingAction*/}
          >
          </GroupActions>
        </div>
        <div className='tableBox'>
          <div className='table'>
            <MagicTable
              store={googleConnectStore}
              checkedField='ID'
              showInputSearch={false}
              noFixedHeader
              simpleHeader
            >
              <CheckboxCell checkedField='ID' field="selectAll" />
              <DateCell field="date" />
              <ScopeCell field="scopes" />
              <StopGoGoogleConnectItemActionsCell field="actions" />
            </MagicTable>
          </div>
        </div>
        {/*<Pagination
          total={googleConnectStore.count}
          perPage={googleConnectStore.perPage}
          page={googleConnectStore.page}
          handleSetPage={(pageNumber) => googleConnectStore.setPage(pageNumber)}
        />*/}
      </div>
      {retrieveModalDisplayed && (
        <RetrieveDataModal
          item={googleConnectItem}
          displayKeywords
          onClose={() => setRetrieveModalDisplayed(false)}
        />
      )}
      {purgeModalDisplayed && (
        <PurgeDataModal
          item={googleConnectItem}
          keywords={googleConnectStore.selectedItems}
          keywordsCount={(googleConnectStore.selectedItems || []).length}
          onClose={() => setPurgeModalDisplayed(false)}
        />
      )}
    </div>
  );
}

export default observer(GoogleConnectData);
