import { useTranslation } from 'react-i18next';
import { DetailsItem } from '../Utils/DetailsItem';
import ReactCountryFlag from 'react-country-flag';
import type { IStopGoKeyword } from '../../../types/stopgokeyword.interface';
import { formatDate } from '../Utils/date';

interface KeywordDetailsProps {
  keyword: IStopGoKeyword | null;
}

export const KeywordDetails = ({
  keyword
}: KeywordDetailsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailsItem
        label={t('Brand')}
        value={keyword?.brand?.label}
      />
      <DetailsItem
        label={t('Keyword')}
        value={keyword?.keyword?.content}
      />
      <DetailsItem
        label={t('Location')}
        value={
          keyword?.keyword?.localization && <ReactCountryFlag
            className="emojiFlag"
            countryCode={keyword?.keyword?.localization?.ISO2}
          />
        }
      />
      <DetailsItem
        label={t('Average ctr')}
        value={keyword?.averageCTR}
      />
      <DetailsItem
        label={t('Average cpc')}
        value={keyword?.averageCPC}
      />
      <DetailsItem
        label={t('Creation date')}
        value={keyword?.createdDate ? formatDate(keyword?.createdDate) : ''}
      />
      <DetailsItem
        label={t('Scenarios')}
        value="-"
      />
      <DetailsItem
        label={t('Excluded')}
        value={keyword?.saveToGoogle ? t('yes') : t('no')}
      />
      <DetailsItem
        label={t('Enabled')}
        value={keyword?.enable ? t('yes') : t('no')}
      />
    </>
  );
};
