import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import FullscreenModal from '../../../components/FullscreenModal/FullscreenModal';
import CancelButton from '../../../components/CancelButton/CancelButton';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import { FormInput } from '../../../components/form/FormInput';
import Backend from '../../../stores/newBackend';

import classes from './AddKeywordModal.module.css';
import { IStopGoBrand } from '../../../types/stopgobrand.interface';
import { SelectAutocomplete } from '../../../components/form/FormAutocomplete';
import { StoreContext } from '../../../stores';
import { SelectInput } from '../../../components/form/FormSelect';

type Inputs = {
  brand: {
    label: string,
    value: string,
  };
  keyword: {
    label: string,
    value: string,
  };
  averageCtr: number;
  averageCpc: number;
}

interface AddKeywordModalPros {
  onClose: (success: boolean) => void;
}

export function AddKeywordModal({
  onClose
}: AddKeywordModalPros) {
  const { t } = useTranslation();
  const { authStore } = useContext(StoreContext);
  const {
    control,
    handleSubmit,
    watch,
  } = useForm<Inputs>();
  const [submitting, setSubmitting] = useState(false);


  const [brands, setBrands] = useState<IStopGoBrand[]>([]);
  const [keywords, setKeywords] = useState<any[]>([]);
  const selectedBrand = watch('brand');

  useEffect(() => {
    setBrands([]);
    const run = async () => {
      const brands = await Backend.loadStopGoBrand(authStore.token);
      setBrands(brands);
    };

    run();
  }, [authStore.token]);


  useEffect(() => {
    setKeywords([]);
    if (!selectedBrand || !selectedBrand.value) {
      return;
    }

    const run = async () => {
      const keywords = await Backend.loadStopGoKeywordLinkable(authStore.token, selectedBrand.value);
      setKeywords(keywords);
    };

    run();
  }, [authStore.token, selectedBrand]);


  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setSubmitting(true);
    try {
      await Backend.addStopGoKeyword(authStore.token, data.brand.value, data.keyword.value, data.averageCpc, data.averageCtr);
      onClose(true);
      toast(t('Added keyword.'), { type: 'success' });
    } catch (err) {
      toast(t('Can\'t add keyword.'), { type: 'error' });
      onClose(false);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FullscreenModal
      title={t('Add keyword')}
      onClose={onClose}
    >
      <div className={classes.modale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectInput
            name="brand"
            label={t('Brand')}
            control={control}
            disabled={submitting}
            options={[...(brands || []).map(b => ({ value: b.ID, label: b.brandLabel }))]}
          />
          <SelectInput
            name="keyword"
            label={t('Keyword')}
            control={control}
            disabled={submitting}
            options={[...(keywords || []).map(k => ({ value: k.ID, label: `${k.content} (${k.localization.country})` }))]}
          />
          <div className={classes.twoFieldsRow}>
            <FormInput
              name="averageCtr"
              label={t('Average CTR')}
              control={control}
              disabled={submitting}
              className={classes.leftField}
            />
            <FormInput
              name="averageCpc"
              label={t('Average CPC')}
              control={control}
              disabled={submitting}
              className={classes.rightField}
            />
          </div>
          {/*<SelectInput
            name="cpcCampaignIds"
            label={t('CPC Campaign IDs')}
            control={control}
            disabled={submitting}
            options={[{ value: 'lcid1 ', label: 'lC ID 1' }]}
            isMulti
          />
          <SelectInput
            name="negativeList"
            label={t('Negative list (default account)')}
            control={control}
            disabled={submitting}
            options={[{ value: 'lcid1 ', label: 'lC ID 1' }]}
            isMulti
          />*/}
          <div className={classes.containerButtons}>
            <CancelButton onClose={onClose} txt={t('Cancel')} />
            <SubmitButton
              isLoading={submitting}
              txt={t('Add keyword')}
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    </FullscreenModal>
  );
}
