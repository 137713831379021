import { useTranslation } from 'react-i18next';
import { DetailsItem } from '../Utils/DetailsItem';
import ScopeCell from '../../../components/MagicTable/ScopeCell/ScopeCell';
import { IStopGoGoogleConnect } from '../../../swagger_client/src/model/StopGoGoogleConnect';
import { useContext, useState } from 'react';
import { StoreContext } from '../../../stores';
import dayjs from 'dayjs';

interface GoogleConnectItemDetailsProps {
  googleConnectItem: IStopGoGoogleConnect;
}

export const GoogleConnectItemDetails = ({
  googleConnectItem
}: GoogleConnectItemDetailsProps) => {
  const { t } = useTranslation();
  const [item, setItem] = useState<IStopGoGoogleConnect>(googleConnectItem);

  return (
    <>
      <DetailsItem
        label={t('Google ads customer')}
        value={item.customerID}
      />
      <DetailsItem
        label={t('Login custom id')}
        value={item.loginCustomerID}
      />
      <DetailsItem
        label={t('Brand')}
        value={item.brandLabel}
      />
      <DetailsItem
        label={t('Expiration')}
        value={item.expiration}
      />
      <DetailsItem
        label={t('Analytics domain')}
        value={item.analyticsDomain}
      />
      <DetailsItem
        label={t('Scopes')}
        value={
          <ScopeCell field="scope" item={item} />
        }
      />
      <DetailsItem
        label={t('Creation date')}
        value={<span>{dayjs(item.createdDate).format('DD/MM/YYYY')}</span>}
      />
    </>
  );
};
