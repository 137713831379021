import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../stores';
import { useTranslation } from 'react-i18next';

import classes from './Brand.module.css';
import DynamicScrollbar from '../../components/DynamicScrollbar/DynamicScrollbar';
import Loader from '../../components/Loader/Loader';
import { BrandItemContent } from './Brand/BrandItemContent';
import { useParams } from 'react-router-dom';

function Brand() {
  const params = useParams() as {[id: string]: string};
  const { uiStore } = useContext(StoreContext),
    { t } = useTranslation();
  const brandId = params.id;
  const loading = false;
  const brand = {};
  const [scrollEnabled] = useState(true);

  useEffect(() => {
    uiStore.setTitle(t('Keyword'));
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`${classes.container} ${loading ? classes.center : ''}`}>
      {loading && <Loader />}
      {loading === false && brand && (
        <DynamicScrollbar
          contentClassName={classes.body}
          noScrollY={!scrollEnabled}
        >
          <BrandItemContent brandId={brandId} />
          { /* <ActionPanels action={action} onModalOpened={onModalOpened} /> */}
        </DynamicScrollbar>
      )}
    </div>
  );
}

export default observer(Brand);
